<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
          :plan-options="planOptions" @refetch-data="refetchData" />
    
        <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
          :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-between w-100">
          <!-- Per Page -->
          <b-col md="6" class="d-flex mt-1">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              class="per-page-selector d-inline-block mx-50 w-25"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
          </b-col>

          <b-col md="6" class="d-flex mt-1">
            <div class="w-100 d-flex justify-content-end">
              <div v-if="searchTerm.type == 'dropdown'">
                <v-select
                  style="width: 200px"
                  placeholder="Search..."
                  :options="categoriesOptions"
                  label="name"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div v-else>
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <!-- <div>
                <v-select
                  style="width: 200px;"
                  placeholder="Search..."
                  :options="searchTerm.name === 'Category' ? getOptions('category') : staticUsers"
                  :label="searchTerm.displayName"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div> -->

              <div class="ml-1">
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block mx-50"
                  label="name"
                  :options="searchOptions"
                  @input="changeSearch()"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="staticUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="onSortChanged"
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <!-- <template #thead-top="{ fields }">
          <tr>
            <th v-for="(field, index) in fields" :key="index">
              <template v-if="field.key == 'id' || field.key == 'Action'">
                &nbsp;
              </template>
              <template v-else>
                <b-form-input
                  v-model="columnFilters[field.key]"
                  @input="filterTable()"
                  placeholder="search..."
                />
              </template>
            </th>
          </tr>
        </template> -->

        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder" style="font-size: 15px">
            {{ data.index + 1 }}
          </span>
        </template>
        <template #cell(f_name)="data">
          <div class="d-flex w-100 align-items-center">
            <div v-if="data.item.user || data.item.admin">
              <span class="mr-1">
                <b-avatar
                  size="32"
                  :src="
                    data.item.admin
                      ? data.item.admin.image
                      : data.item.user.image
                  "
                  :text="displayedName(data.item).substring(0, 1)"
                />
              </span>
              <span class="font-weight-bolder title">
                {{ displayedName(data.item) }}
              </span>
            </div>
          </div>
        </template>
        <template #cell(title)="data">
          <div
            style="cursor: pointer"
            class="d-flex w-100"
            @click="openVideoModal(data.item)"
          >
            <!-- <video v-else class="text-dark w-100 ImageLink" ref="videoPlayer" controls>
                            <source :src="data.item.url" type="video/mp4">
                          </video> -->
            <template v-if="data.item && data.item.thumbnail">
              <img :src="data.item.thumbnail" width="50" height="50" />
            </template>
            <template v-else>
              <span>
                <img
                  style="height: 40px; width: 40px"
                  src="../../../../../../public/no-image.png"
                  alt=""
                />
              </span>
            </template>
            <span class="font-weight-bold ml-1 text-primary title">{{
              data.item.title
            }}</span>
          </div>
        </template>
        <!-- <template #cell(Views)="data">
          <div class="d-flex w-100 align-items-center">
            <span class="font-weight-bolder title">
              <p class="m-0">{{ data.item.view_count }}</p>
            </span>
          </div>
        </template> -->
        <template #cell(videocategory_ids)="data">
          <div class="d-flex w-100 align-items-center">
            <span class="font-weight-bolder title">
              <p class="m-0">{{ data.item && data.item.videocategory.length>0 ? setCategory(data.item.videocategory) :'' }}</p>
            </span>
          </div>
        </template>
        <template #cell(open)="data">
          <div class="d-flex w-100 align-items-center tableData">
          <span class="text-primary font-weight-bolder">
            <b-form-checkbox
              v-model="data.item.open"
              class="mr-0"
              switch
              inline
              @change="changeOpenVideoStatus($event, data.item)"
            />
          </span>
          </div>
        </template>
        <template #cell(Action)="data">
          <div class="d-flex align-items-center" style="cursor: pointer">
            <feather-icon
              icon="Trash2Icon"
              size="22"
              class="m-1"
              @click="removeVideo(data.item.id)"
            />
            <!-- <b-icon icon="three-dots-vertical" @click="handleEditForm(data.item)" font-scale="4"></b-icon> -->
            <b-dropdown id="dropdown-1" class="m-md-2" no-caret variant="link">
              <template v-slot:button-content>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  class="bi bi-three-dots-vertical"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
                  />
                </svg>
              </template>
              <b-dropdown-item @click.prevent="handleEditForm(data.item, false)"
                >Edit Video</b-dropdown-item
              >
              <b-dropdown-item @click.prevent="handleEditForm(data.item, true)"
                >Hide Video</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <b-modal
        hide-footer
        v-model="EditVideo"
        no-close-on-backdrop
        centered
        size="md"
      >
        <validation-observer ref="Rules">
          <b-row class="p-2">
            <b-col md="12" class="d-flex align-items-center flex-column">
              <h2 style="font-size: 26px" class="font-weight-bolder lableTitle">
                Video Information
              </h2>
            </b-col>
            <b-col cols="12" >
              <b-form-group>
                <label class="font-weight-bold mt-1" 
                  >Upload Thumbnail</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Thumbnail"
                >
                  <b-form-file
                    v-model="videoThumbnail"
                    placeholder="Upload Photo"
                    @input="uploadDoc($event, 'videoThumbnail')"
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-img :src="videoThumbnail" height="70" width="70" class="m-1"></b-img> -->

            </b-col>
             <b-col cols="12" >
              <b-form-group>
                <label class="font-weight-bold mt-1" 
                  >Upload Video</label
                >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Video"
                >
                  <b-form-file
                    v-model="video"
                    placeholder="Upload Video"
                    @input="uploadDoc($event, 'video')"
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-img :src="videoThumbnail" height="70" width="70" class="m-1"></b-img> -->

            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-trainer"
                  >Enter Video Title</label
                >
                <b-form-input v-model="videoTitle" placeholder="Title" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-trainer"
                  >Enter Video Category</label
                >
                <v-select
                    
                    v-model="videoCategory"
                    label="name"
                    :options="categoriesOptions"
                  multiple

                    placeholder="Workout Category"
                  ></v-select>
              </b-form-group>
            </b-col>
            <b-col md="12" class="d-flex justify-content-center mt-2">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="submitForm()"
                :disabled="flag"
                style="font-family: Public Sans"
              >
                Submit
              </b-button>
              <!-- handleEditVideo('hide', staticUsers.id) -->
              <b-button
                type="reset"
                @click="CloseModal"
                variant="primary"
                style="font-family: Public Sans"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
      <div class="w-100 d-flex justify-content-between p-1">
        <!-- Pagination -->
        <div>
          <span class="text-nowrap">
            Showing {{ paginationStart }} to {{ paginationEnd }} of
            {{ paginationTotal }}</span
          >
        </div>
        <b-pagination
          :total-rows="paginationTotal"
          :per-page="per_page"
          v-model="paginationValue"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="changePagination()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
    <b-modal
      v-model="openVideoModel"
      :title="selectedVideo ? selectedVideo.title : ''"
      class="text-primary"
      hide-footer
    >
      <video
        :src="selectedVideo ? selectedVideo.url : ''"
        controls
        autoplay
        class="w-100"
      ></video>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BIcon,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormFile,
  BImg,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import _ from "lodash";
import { required } from '@validations'

export default {
  components: {
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BIcon,
    BImg,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    vSelect,
    BFormFile,
    BFormCheckbox

  },
  data() {
    return {
      required,
      tableColumns: [
        {
          key: "id",
          label: "NO",
          sortable: true,
        },
        { key: "title", label: "Video", sortable: true },
        { key: "videocategory_ids", label: "Category",  },
        { key: "f_name", label: "Client",  },
        { key: "open", label: "Open For All",  },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: "view_count", label: "Views", },
        // { key: 'balance', sortable: true },
        { key: "Action", label: "Action" },
      ],
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      videoTitle: "",
      videoThumbnail: "",
      videoCategory: "",
      isSortDirDesc: true,
      EditVideo: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      staticUsers: [],
      categories: [],
      isLoading: false,
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      params: {},
      columnFilters: {},
      searchTerm: { name: "Title", value: "title" },
      search: "",
      searchOptions: [
        { name: "Title", value: "title" },
        {
          name: "Category",
          value: "videocategory_ids",
          type: "dropdown",
        },
      ],
      openVideoModel: false,
      selectedVideo: null,
      categoriesOptions:[],
      flag:false,
      video:''
    };
  },
  computed: {
    processedUsers() {
      return this.staticUsers.map((user) => {
        const avatars = user.avatars || [];
        const topThreeAvatars = avatars.slice(0, 3);
        const remainingCount = Math.max(avatars.length - 3, 0);
        return { ...user, topThreeAvatars, remainingCount };
      });
    },
    displayedName() {
      return (item) => {
        if (item.admin) {
          return item.admin.f_name + ' - Admin';
        } else if (item.user) {
          return item.user.f_name + (item.user.type == 'customer'?' - Client':' - Trainer') ;
        }
        return "";
      };
    },
    setCategory() {
      return (item) => {
        let arr = item.filter((inner) => inner);
        return arr.map(inner=>inner.name).join(', ')
      }
    }
  },
  mounted() {
    this.getVideo();
    // this.addVideo();
    this.getCategories();
  },
  methods: {
    async uploadDoc(event, variable) {
      this.flag = true;
      if (event) {
        const formData = new FormData();
        formData.append("url", event);
        formData.append("type", "image");
        await axios({
          method: "POST",
          url: `${this.baseApi}attachments/uploadattachment`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: formData,
        }).then(async (response) => {
          //   console.log(response.data.data.url)
          //   let data = {
          //     url: response.data.data.url,
          //     admin_id: JSON.parse(localStorage.getItem('userData')).id,
          //     status: 1
          //   }
          this[variable] = response.data.data.url;
        })
        .finally(()=>{
          this.flag = false;

        })
      }
    },
    async changeOpenVideoStatus(event,item){
      let data={
        open:event ? 1 : 0
      }
      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}video/change-video-status/${item.id}`,
        data
      }
      await axios(requestOptions).catch((res)=>{
        item.open=!event

      })
    },
      async getCategories(){
      const requestOptions = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}videocategories/all`
      }
      await axios(requestOptions).then((res)=>{
        this.categoriesOptions = res.data.data ?? []
      })
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getVideo();
    },
    openVideoModal(item) {
      this.selectedVideo = item;
      this.openVideoModel = true;
    },
    handleSearch: _.debounce(function () {
      this.getVideo();
    }, 1000),

    changeSearch() {
      this.search = "";
      if (this.search) {
        this.getVideo();
      }
    },
    // handleEditForm(item) {
    //     this.EditVideo = true
    //     this.videoTitle = item.title
    //     this.id = item.id
    // },
    
    async getVideo() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}video/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((response) => {
          this.isLoading = false;
          this.staticUsers = response.data.data;
          this.paginationTotal = response.data.total;
          this.paginationStart = response.data.from;
          this.paginationEnd = response.data.to;
          this.isLoading = false;
          this.staticUsers.map((item)=>{
            item.open = item.open?true:false
          })
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },
    // handleEditForm(item, hide) {
    //     const { id, title } = item;
    //     this.handleEditVideo(hide, id, title);
    //     this.EditVideo = true
    //     this.videoTitle = item.title
    // },
    handleEditForm(item, hide) {
      const status = hide ? 0 : 1
      const { id, title, videocategory,thumbnail,url } = item;
      this.customerData = { title: title, status, id };
      if(!hide){
        this.videoTitle = title;
        this.videoCategory = videocategory;
        this.EditVideo = true;
        this.videoThumbnail =thumbnail;
        this.video=url;
      }
      else{
        this.handleEditVideo()
      }
    },
    async handleEditVideo() {
      this.flag = true
      const { title, status, id } = this.customerData;

      let videoCategory_arr = this.videoCategory.map((item) => item.id);
      const requestOptions = {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}video/update/${id}`,
        data: JSON.stringify({
          title: this.videoTitle,
          thumbnail:this.videoThumbnail,
          url:this.video,
          // main_exercise_id: this.videoCategory?this.videoCategory.id:'',
                   videocategory_ids: videoCategory_arr.join(','),

          status,
          id,
        }),
      };
      await axios(requestOptions)
        .then(() => {
          this.$swal({
            title: "Submitted",
            text: "Video Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getVideo();
          this.EditVideo = false;
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: `${error}`,
            icon: "error",
            timer: 5000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .finally(()=>{
          this.flag= false
        })
    },
    submitForm() {
      this.handleEditVideo();
    },
    async removeVideo(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}video/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.staticUsers.findIndex(
                  (item) => item.id === id
                );
                if (index !== -1) {
                  this.staticUsers.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    changePagination() {
      this.getVideo();
    },
    openProfile(item) {
      const userId = item.id;
      this.$router.push({ path: `/trainermanagement/${userId}` });
    },
    openTrainerProfile(item) {
      const userId = item.Trainer;
      this.$router.push({ path: `/trainerprofile/${userId}` });
    },
    openModal() {
      this.AddNewTrainer = true;
    },
    CloseModal() {
      this.EditVideo = false;
    },
  },
};
</script>
<style>
.avatar-container {
  display: flex;
  align-items: center;
}

.title {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
}

.remaining-count {
  margin-left: 5px;
  font-weight: bold;
}
</style>